import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import StatCard from './StatCard';

const Dashboard = () => {
    return (
        <div className="dashboard">
            <Sidebar />
            <main className="main-content">
                <Header />
                <div id="dashboard" className="page-content active">
                    <h1 className='mb-5'>Dashboard Overview</h1>

                    {/* Bootstrap grid layout */}
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 mb-4">
                                <StatCard icon="fas fa-users" title="Total Users" value="15,687" />
                            </div>
                            {/* <div className="col-lg-3 col-md-6 mb-4">
                                <StatCard icon="fas fa-shopping-bag" title="Total Sales" value="$124,563" />
                            </div>
                            <div className="col-lg-3 col-md-6 mb-4">
                                <StatCard icon="fas fa-chart-line" title="Revenue" value="$67,895" />
                            </div>
                            <div className="col-lg-3 col-md-6 mb-4">
                                <StatCard icon="fas fa-percent" title="Growth" value="+24.5%" />
                            </div> */}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Dashboard;
