// import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Components/Login';
import Dashboard from './Components/Dashboard';
import UserTable from './Components/UserTable';
import Vclist from './Components/VcList';
import LabUserList from './Components/LabUserList'
import Registration from './Components/Registration';
import FeedbackVclist from './Components/FeedbackVclist'
import AllUserList from './Components/AllUserList';

// A helper function to check for a token in cookies
const checkAuthToken = () => {
  const cookies = document.cookie.split('; ');
  const token = cookies.find(row => row.startsWith('onrequest='));
  return token ? true : false; // Returns true if token exists
};

function App() {
  const [isAuth, setIsAuth] = useState(false); // State to hold the authentication status

  useEffect(() => {
    // Check for authentication status when the component mounts
    const tokenExists = checkAuthToken();
    setIsAuth(tokenExists);
  }, []); // Empty dependency array to run once on mount

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={isAuth ? <Navigate to="/dashboard" /> : <Login />} />
        <Route path='/dashboard' element={isAuth ? <Dashboard /> : <Navigate to="/login" />} />
        <Route path='/usertable' element={isAuth ? <UserTable /> : <Navigate to="/login" />} />
        <Route path='/vclist' element={isAuth ? <Vclist /> : <Navigate to="/login" />} />
        <Route path='/lab-user-list' element={isAuth ? <LabUserList /> : <Navigate to="/login" />} />
        <Route path='/registration' element={isAuth ? <Registration /> : <Navigate to="/login" />} />
        <Route path='/feedbackVclist' element={isAuth ? <FeedbackVclist /> : <Navigate to="/login" />} />
        <Route path='/allUserList' element={isAuth ? <AllUserList /> : <Navigate to="/login" />} />
        {/* Redirect all other routes to login */}
        <Route path='*' element={<Navigate to="/login" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
