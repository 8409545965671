import React from 'react';
import { NavLink } from 'react-router-dom';
import Cookies from 'js-cookie'; 

const Sidebar = () => {

  const logout = () => {
    Cookies.remove("onrequest"); 
    window.location.href = "/login"; 
  };

  return (
    <nav className="sidebar">
      <div className="sidebar-header">
        <img src="https://via.placeholder.com/50" alt="Logo" className="logo" />
        <h2>Admin</h2>
      </div>
      <ul className="nav-links">
        <li data-page="dashboard">
          <NavLink 
            to="/dashboard" 
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <i className="fas fa-home"></i>Dashboard
          </NavLink>
        </li>
        <li data-page="user-table">
          <NavLink 
            to="/usertable" 
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <i className="fas fa-table"></i>User Table
          </NavLink>
        </li>
        <li data-page="vc-list">
          <NavLink 
            to="/vclist" 
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <i className="fas fa-chart-line"></i>VC List
          </NavLink>
        </li>
        <li data-page="lab-user-list">
          <NavLink 
            to="/lab-user-list" 
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <i className="fas fa-chart-line"></i>Lab User List
          </NavLink>
        </li>
        <li data-page="registration">
          <NavLink 
            to="/registration" 
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <i className="fas fa-file-alt"></i>Registration Form
          </NavLink>
        </li>
        <li data-page="feedbackVclist">
          <NavLink 
            to="/feedbackVclist" 
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <i className="fas fa-chart-line"></i>Feedback Vc List
          </NavLink>
        </li>
        <li data-page="allUserList">
          <NavLink 
            to="/allUserList" 
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <i className="fas fa-chart-line"></i>All User Data
          </NavLink>
        </li>
        <li 
          data-page="logout" 
          onClick={logout} 
          style={{ cursor: 'pointer', color: 'var(--text-secondary)' }} // Added inline CSS
        >
          <i className="fas fa-sign-out-alt"></i>Logout
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
