import axios from 'axios';

export async function getServices(url, parameter) {
    let content = 'application/json'
    const API_URL =  'https://backend.onrequestlab.com/' + url 
                   

    try {
        const response = await axios.post(
            API_URL,
            parameter,
            {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Accept-Language': 'en-IN,en-GB;q=0.9,en-US;q=0.8,en;q=0.7,ta;q=0.6,gu;q=0.5',
                    'Connection': 'keep-alive',
                    'Content-Type': 'application/json',
                    // 'DNT': '1', // Uncomment if you want to include Do Not Track
                    'Origin': 'http://d3goakkuhrdi7b.cloudfront.net',
                    'Referer': 'http://d3goakkuhrdi7b.cloudfront.net/',
                    'User-Agent': 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36'
                }

                // headers: {
                //     'Content-Type': 'application/json',
                // }
            }
        );

        if (response.data.msg === 'Session not found, Or Token not found in header') {
            alert("Session not found, Or Token not found in header");
            window.location.href = "/auth/login"; // Consider using React Router here
        }

        return response;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            sessionStorage.removeItem("onrequest");
        } else {
            // console.error('Error occurred:', error); // Log the error for debugging
            return error.response ? error.response : { status: "error", message: "Something went wrong!" };
        }
    }
}

export async function getServicesGETMethod(url, parameter) {
    let content = 'application/json'
    const API_URL =  'https://backend.onrequestlab.com/' + url 
    try {
        const response = await axios.get(
            API_URL,
            {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Accept-Language': 'en-IN,en-GB;q=0.9,en-US;q=0.8,en;q=0.7,ta;q=0.6,gu;q=0.5',
                    'Connection': 'keep-alive',
                    'Content-Type': 'application/json',
                    // 'DNT': '1', // Uncomment if you want to include Do Not Track
                    'Origin': 'http://d3goakkuhrdi7b.cloudfront.net',
                    'Referer': 'http://d3goakkuhrdi7b.cloudfront.net/',
                    'User-Agent': 'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36'
                }

                // headers: {
                //     'Content-Type': 'application/json',
                // }
            }
        );

        if (response.data.msg === 'Session not found, Or Token not found in header') {
            alert("Session not found, Or Token not found in header");
            window.location.href = "/auth/login"; // Consider using React Router here
        }

        return response;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.clear();
            sessionStorage.clear();
            sessionStorage.removeItem("onrequest");
        } else {
            // console.error('Error occurred:', error); // Log the error for debugging
            return error.response ? error.response : { status: "error", message: "Something went wrong!" };
        }
    }
}


