import React, { useMemo, useState } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { CSVLink } from 'react-csv';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import '../DataTable.css'; 

const DataTable = ({ columns, data }) => {
    const [searchTerm, setSearchTerm] = useState('');

    // Filter data based on search term
    const filteredData = useMemo(() => {
        return data.filter(item => 
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [data, searchTerm]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page, // Use the page array for pagination
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data: filteredData,
            initialState: { pageIndex: 0 }, // Start at page 0
        },
        useSortBy,
        usePagination // Enable pagination
    );

    return (
        <div className="data-table-container">
            <div className="d-flex justify-content-between align-items-center mb-2">
                <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    className="form-control me-2 searchBox_datatable"
                />
                <CSVLink data={filteredData} filename="data.csv" className="btn btn-primary csvbtn">
                    Download CSV
                </CSVLink>
            </div>

            <table {...getTableProps()} className="table table-bordered">
                <thead className="thead-light">
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            <div className="pagination">
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<< Previous'}
                </button>
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'Next >>'}
                </button>
            </div>
        </div>
    );
};

export default DataTable;
