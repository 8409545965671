import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faGooglePlusG, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { getServices } from './services'; 
import '../login.css';

// Function to generate a random token
const generateToken = (length = 20) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let token = '';
  for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      token += characters[randomIndex];
  }
  return token;
};

const Login = () => {
  const [signUpData, setSignUpData] = useState({ name: '', email: '', password: '' });
  const [signUpErrors, setSignUpErrors] = useState({});

  const [signInData, setSignInData] = useState({ email: '', password: '' });
  const [signInErrors, setSignInErrors] = useState({});

  useEffect(() => {
      const signUpButton = document.getElementById('signUp');
      const signInButton = document.getElementById('signIn');
      const container = document.getElementById('container');

      if (signUpButton && signInButton && container) {
          signUpButton.addEventListener('click', () => {
              container.classList.add("right-panel-active");
          });

          signInButton.addEventListener('click', () => {
              container.classList.remove("right-panel-active");
          });

          return () => {
              signUpButton.removeEventListener('click', () => {
                  container.classList.add("right-panel-active");
              });

              signInButton.removeEventListener('click', () => {
                  container.classList.remove("right-panel-active");
              });
          };
      }
  }, []);

  const handleInputChange = (e, form) => {
      const { name, value } = e.target;
      if (form === 'signUp') {
          setSignUpData({ ...signUpData, [name]: value });
      } else {
          setSignInData({ ...signInData, [name]: value });
      }
  };

  const validateSignUp = () => {
      const errors = {};
      if (!signUpData.name) {
          errors.name = 'Name is required';
      }
      // if (!signUpData.email) {
      //     errors.email = 'Email is required';
      // } else if (!/\S+@\S+\.\S+/.test(signUpData.email)) {
      //     errors.email = 'Email is invalid';
      // }
      if (!signUpData.password) {
          errors.password = 'Password is required';
      } else if (signUpData.password.length < 6) {
          errors.password = 'Password must be at least 6 characters';
      }
      setSignUpErrors(errors);
      return Object.keys(errors).length === 0;
  };

  const validateSignIn = () => {
      const errors = {};
      // if (!signInData.email) {
      //     errors.email = 'Email is required';
      // } else if (!/\S+@\S+\.\S+/.test(signInData.email)) {
      //     errors.email = 'Email is invalid';
      // }
      if (!signInData.password) {
          errors.password = 'Password is required';
      }
      setSignInErrors(errors);
      return Object.keys(errors).length === 0;
  };

  // Handle Sign Up form submission
  const handleSignUpSubmit = (e) => {
      e.preventDefault();
      if (validateSignUp()) {
          Swal.fire({
              icon: 'success',
              title: 'Sign Up Successful!',
              text: 'Account created successfully.',
          });
      }
  };

  // Handle Sign In form submission
  const handleSignInSubmit = async (e) => {
      e.preventDefault();

      if (validateSignIn()) {
          try {
              const parameters = {
                  username: signInData.email,
                  password: signInData.password,
              };

              const response = await getServices('api/v1/users/login/', parameters);
              console.log("responseee",response);
              

              if (response.data.status_code === 200) {
                  const token = generateToken(); // Generate the random token

                  // Store the generated token in a cookie
                  Cookies.set('onrequest', token, { expires: 1 });

                  // Success: handle the login response
                  Swal.fire({
                      icon: 'success',
                      title: 'Sign In Successful!',
                      text: 'Logged in successfully.',
                  });

                  window.location.href = "/dashboard";
              } else {
                  // Handle login failure
                  Swal.fire({
                      icon: 'error',
                      title: 'Sign In Failed!',
                      text: response.data.msg,
                  });
              }
          } catch (error) {
              // Handle API error
              Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'Something went wrong during login. Please try again later.',
              });
          }
      }
  };

  return (
      <div className='login-page'>
          <div className="container login-page-container" id="container">
              <div className="form-container sign-up-container">
                  <form className='login-form' onSubmit={handleSignUpSubmit}>
                      <h1>Create Account</h1>
                      <div className="social-container">
                          <a href="#" className="social"><FontAwesomeIcon icon={faFacebookF} /></a>
                          <a href="#" className="social"><FontAwesomeIcon icon={faGooglePlusG} /></a>
                          <a href="#" className="social"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                      </div>
                      <span>or use your email for registration</span>
                      <input
                          className='login-form-input'
                          type="text"
                          placeholder="Name"
                          name="name"
                          value={signUpData.name}
                          onChange={(e) => handleInputChange(e, 'signUp')}
                      />
                      {signUpErrors.name && <p className="error">{signUpErrors.name}</p>}
                      <input
                          className='login-form-input'
                          type="text"
                          placeholder="Email"
                          name="email"
                          value={signUpData.email}
                          onChange={(e) => handleInputChange(e, 'signUp')}
                      />
                      {signUpErrors.email && <p className="error">{signUpErrors.email}</p>}
                      <input
                          className='login-form-input'
                          type="password"
                          placeholder="Password"
                          name="password"
                          value={signUpData.password}
                          onChange={(e) => handleInputChange(e, 'signUp')}
                      />
                      {signUpErrors.password && <p className="error">{signUpErrors.password}</p>}
                      <button type="submit">Sign Up</button>
                  </form>
              </div>

              <div className="form-container sign-in-container">
                  <form className='login-form' onSubmit={handleSignInSubmit}>
                      <h1>Sign In</h1>
                      <div className="social-container">
                          <a href="#" className="social"><FontAwesomeIcon icon={faFacebookF} /></a>
                          <a href="#" className="social"><FontAwesomeIcon icon={faGooglePlusG} /></a>
                          <a href="#" className="social"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                      </div>
                      <span>or use your account</span>
                      <input
                          className='login-form-input'
                          type="text"
                          placeholder="Email"
                          name="email"
                          value={signInData.email}
                          onChange={(e) => handleInputChange(e, 'signIn')}
                      />
                      {signInErrors.email && <p className="error">{signInErrors.email}</p>}
                      <input
                          className='login-form-input'
                          type="password"
                          placeholder="Password"
                          name="password"
                          value={signInData.password}
                          onChange={(e) => handleInputChange(e, 'signIn')}
                      />
                      {signInErrors.password && <p className="error">{signInErrors.password}</p>}
                      <a href="#">Forgot your password?</a>
                      <button type="submit">Sign In</button>
                  </form>
              </div>

              <div className="overlay-container">
                  <div className="overlay">
                      <div className="overlay-panel overlay-left">
                          <h1>Welcome Back!</h1>
                          <p>To keep connected with us please login with your personal info</p>
                          <button className="ghost" id="signIn">Sign In</button>
                      </div>
                      <div className="overlay-panel overlay-right">
                          <h1>Hello, Friend!</h1>
                          <p>Enter your personal details and start journey with us</p>
                          <button className="ghost" id="signUp">Sign Up</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  );
};

export default Login;