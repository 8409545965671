// src/Components/Table.js
import React, { useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import Swal from 'sweetalert2';
import '../RegistrationTable.css'
import { getServices } from './services'; 

const UserTable = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        username:'',
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await getServices('api/v1/users/register/'); 
            console.log("responseee", response);
    
            if (response.status === 200 || response.status === 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Registration Successful',
                    text: response.data.msg || 'Your account has been created successfully!',
                });
    
                // Reset the form
                setFormData({
                    first_name: '',
                    last_name: '',
                    username:'',
                    email: '',
                    password: '',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Registration Failed',
                    text: response.data.msg || 'Something went wrong. Please try again.',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Something went wrong during the registration process. Please try again later.',
            });
        }
    };
    

    return (
        <div className="dashboard">
            <Sidebar />
            <main className="main-content">
                <Header />
                <div className="container">
                    <h1 className="my-4">User Registration</h1>
                    <form onSubmit={handleSubmit} className="registration-form shadow p-4">
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label htmlFor="first_name">First Name</label>
                                <input
                                    type="text"
                                    id="first_name"
                                    name="first_name"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="last_name">Last Name</label>
                                <input
                                    type="text"
                                    id="last_name"
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="col-md-6 mt-4">
                                <label htmlFor="username">User Name</label>
                                <input
                                    type="text"
                                    id="username"
                                    name="username"
                                    value={formData.username}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                        
                            <div className="col-md-6 mt-4">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                            <div className="col-md-6 mt-4 mb-2">
                                <label htmlFor="password">Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    className="form-control"
                                    required
                                />
                            </div>
                        </div>
                        <div className="col-md-2 mt-4 mb-2">
                        <button type="submit" className="btn btn-primary">Register</button>
                        </div>
                    </form>
                </div>
            </main>
        </div>
    );
};

export default UserTable;
