// src/Components/Table.js
import React, { useEffect, useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import DataTable from './DataTable';
import { getServicesGETMethod } from './services'; 
import Swal from 'sweetalert2';

const FeedbackVclist = () => {
    const [data, setData] = useState([]); // State to hold the fetched data

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getServicesGETMethod('api/feedback/feedback_vc/');
                console.log("responseee", response);
                
                // Check if the response data is an array
                let finalData = response.data;
                if (Array.isArray(finalData)) {
                    // Sort data by id in descending order
                    finalData.sort((a, b) => b.id - a.id);
                    setData(finalData); // Update state with sorted data
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'No Data Found',
                        text: response.data.msg,
                    });
                }
            } catch (error) {
                // Handle API error
                console.log(error)
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Something went wrong during fetching data. Please try again later.',
                });
            }
        };

        fetchData(); // Call the async function
    }, []); // Empty dependency array means this runs once on mount

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString('en-US', {
            year: 'numeric', 
            month: 'long', 
            day: 'numeric', 
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit', 
            hour12: true 
        });
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'SR. NO.',
                accessor: 'id', // still using `id` to access data
                Cell: ({ row }) => <span>{row.index + 1}</span>, // display SR. NO. based on row index
            },
            {
                Header: 'Feedback id',
                accessor: 'feedback_id',
            },
            {
                Header: 'Username',
                accessor: 'user',
            },
            {
                Header: 'Subject',
                accessor: 'subject',
            },
            {
                Header: 'Description',
                accessor: 'description',
            },
            {
                Header: 'Time',
                accessor: 'timestamp',
                Cell: ({ value }) => formatTimestamp(value), // Format the timestamp here
            },
            // {
            //     Header: 'Action',
            //     Cell: () => (
            //         <div>
            //             <button className="btn btn-outline-primary btn-sm">Edit</button>&nbsp; &nbsp;
            //             <button className="btn btn-outline-danger btn-sm">Delete</button>
            //         </div>
            //     ),
            // },
        ],
        []
    );

    return (
        <div className="dashboard">
            <Sidebar />
            <main className="main-content">
                <Header />
                <div>
                    <h1>VC List</h1>
                    <DataTable columns={columns} data={data} />
                </div>
            </main>
        </div>
    );
};

export default FeedbackVclist;
